window.SignUp = {
    init() {
        this.validateForm();
        this.handleAnalytics();
        // this.emailVerified();
        let domain = ''
    },
    emailVerifiedMsg() {
        return `Your account is already created  please sign in to continue.`
    },
    emailProviderMsg() {
        return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
    },

    validateForm() {
        $("#new_user").validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "user[email]": {
                    required: true,
                    checkEmail: true,
                    checkEmailDomain: true,
                    emailVerified: true
                },
                "user[confirm_email]": {
                    required: true,
                    equalTo: '#email'
                }
            },
            messages: {
                "user[email]": {
                    checkEmail: 'Email already taken'
                },
                "user[confirm_email]": {
                    equalTo: 'Please enter the same email.'
                }
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element.parent());
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });

        $.validator.addMethod("checkEmail", function (value) {
            var alreadTaken = false
            $.ajax({
                url: `registration/check_email`,
                data: {check_exists: true, 'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    alreadTaken = data
                }
            });
            return alreadTaken;
        });

        $.validator.addMethod("equalTo", function (value, element, param) {
            return this.optional(element) || value.toLowerCase() === $(param).val().toLowerCase();
        }, 'Please enter the same email.');

        $.validator.addMethod("checkEmailDomain", function (value) {
            let emailProvider = false
            domain = value.split("@")[1]
            $.ajax({
                url: `registration/check_email_domain`,
                data: {'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    emailProvider = data;
                }
            });
            return emailProvider;
        }, this.emailProviderMsg);

        $.validator.addMethod("emailVerified", function (value) {

            var alreadyconfirmed = false
            $.ajax({
                url: `registration/check_email_verified`,
                data: {'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    alreadyconfirmed = data
                }
            });
            if (!alreadyconfirmed) {
                // window.location.replace(`/verify-email?email=${value}`)
            } else {
                return alreadyconfirmed;
            }
        }, this.emailVerifiedMsg);
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.size === 0) {
                analytics.page("", ANALYTICS_EVENTS.USER_TYPE_PAGE_VIEWED)
            } else if (urlParams.has('supplier', 'true')) {
                analytics.page("", ANALYTICS_EVENTS.SUPPLIER_EMAIL_PAGE_VIEWED)
            }

            const companyCategoryButton = document.getElementById("company_category_btn");
            if (companyCategoryButton) {
                companyCategoryButton.addEventListener("click", () => {
                    let userType = "";
                    const userTypeCheckboxes = document.querySelectorAll('input[type="checkbox"]');
                    userTypeCheckboxes.forEach(checkbox => {
                        if (checkbox.checked) {
                            userType = checkbox.id
                        }
                    })

                    analytics.track(ANALYTICS_EVENTS.USER_TYPE_SUBMITTED, {
                        user_type: userType
                    });
                });
            }

            const signupButton = document.getElementById("sign-up-btn");
            if (signupButton) {
                signupButton.addEventListener("click", () => {
                    analytics.track(ANALYTICS_EVENTS.SUPPLIER_EMAIL_SUBMITTED, {
                        email: document.getElementById("email").value,
                        email_confirm: document.getElementById("confirm_email").value,
                    });
                });
            }

            const signinLink = document.getElementById("sign-in-btn");
            analytics.trackLink(signinLink, ANALYTICS_EVENTS.SIGN_IN_SELECTED);
        });
    },
};
