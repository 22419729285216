import $ from 'jquery';
import 'bootstrap-star-rating/js/star-rating.min';
import 'bootstrap-star-rating/css/star-rating.min.css';

import emptyStarPath from '../../../assets/images/empty-star-sm-solid.svg';
import filledStarPath from '../../../assets/images/filled-star-sm-solid.svg';

window.Rating = {
    init(id) {
        this.initRating(id);
    },

    initRating(id) {
        $(id).rating({
            min: 0,
            max: 5,
            step: 1,
            stars: 5,
            size: 'sm',
            showClear: false,
            showCaption: false,
            emptyStar: `<img src=${emptyStarPath} />`,
            filledStar: `<img src="${filledStarPath}" />`
        });

        $(id).rating('reset');
    }
};
