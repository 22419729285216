window.CompanyInfo = {
    init() {
        this.validateCompanyDetailForm();
        this.handleAnalytics();
    },

    validateCompanyDetailForm() {
        $("#compnay_info_form").validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            // onfocusout: false,
            rules: {
                "company_detail[range_of_inception]": {
                    required: true,
                },
                "company_detail[number_of_employees]": {
                    required: true,
                },
                "company_detail[country_id]": {
                    required: true,

                },
                "company_detail[yearly_turnover]": {
                    required: true
                }
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.FINAL_DETAILS_PAGE_VIEWED);

            const companyInfoSubmitButton = document.getElementById("company-info-submit-btn");
            companyInfoSubmitButton.addEventListener("click", () => {
                const companyInfoForm = document.getElementById("company_info_form");
                analytics.track(ANALYTICS_EVENTS.FINAL_DETAILS_SUBMITTED, {
                    company_age: companyInfoForm.elements["company[company_detail_attributes][range_of_inception]"].value,
                    employee_count: companyInfoForm.elements["company[company_detail_attributes][number_of_employees]"].value,
                    company_hq: companyInfoForm.elements["company[company_detail_attributes][country_id]"].selectedOptions[0].textContent,
                    company_turnover: companyInfoForm.elements["company[company_detail_attributes][yearly_turnover]"].value,
                    company_currency: companyInfoForm.elements["company[company_detail_attributes][currency_id]"].selectedOptions[0].textContent,
                });
            });
        });
    }
};