import {WEBSITE_URL_REGEX} from "../constants";

const AUTH_TOKEN = $('meta[name="csrf-token"]').attr('content');

window.UpdateDealSampleRequest = {
    init() {
        this.validateAddTrackingDealSampleRequestForm();
        this.initDropZone();
    },

    validateAddTrackingDealSampleRequestForm() {
        $('#add-tracking-sample-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "tracking_url": {
                    required: true,
                    websiteUrl: true
                },
                "expected_delivery_date": {
                    required: true
                },
            },
            messages: {
                'tracking_url': 'Please provide a valid tracking url',
                'expected_delivery_date': 'Please provide the expected delivery date'
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });

        $.validator.addMethod('websiteUrl', function (value) {
            return WEBSITE_URL_REGEX.test(value);
        }, 'Please enter a valid tracking url.');
    },

    initDropZone() {
        const submitButton = document.getElementById('add-tracking-sample-request-modal-btn');
        const self = this
        $(".dropzone").not(".dz-clickable").each((index, element) => {
            $(element).dropzone({
                url: "/deal_sample_requests/upload_deal_sample_request_attachment",
                previewsContainer: $(element).siblings('.drop-zone-preview')[0],
                previewTemplate: document.querySelector('.drop-zone-custom').innerHTML,
                uploadMultiple: false,
                maxFiles: 10,
                maxFileSize: 10,
                acceptedFiles: ".jpg,.jpeg,.gif,.png,.svg,.tiff,.bmp,.webp,.txt,.csv,.pdf,.xls,.xlsx,.doc,.docx",
                init: function () {
                    this.on("error", function (file) {
                        this.removeFile(file);
                        $('#deal-sample-request-attachments').after(
                            '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">' +
                            '<button type="button" class="close" ' +
                            'data-dismiss="alert" aria-hidden="true">' +
                            '&times;' +
                            '</button>' +
                            'An error has occurred uploading file' +
                            '</div>'
                        );
                        submitButton.disabled = false;
                    });
                },
                params: {
                    'authenticity_token': AUTH_TOKEN
                },
                success: (file, response) => {
                    if (response.err_msg == '' || response.err_msg == undefined) {
                        const dealSampleRequestAttachmentsElement = $("#deal-sample-request-attachments");
                        $(file.previewElement).find('.remove-attachment').attr('data-attachment-id', response.id);
                        dealSampleRequestAttachmentsElement.append(`<input type='hidden' value='${response.id}' name='deal_sample_request_attachment_ids[]'>`);
                        self.handleOnClickRemoveAttachment();
                    } else {
                        alert(response.err_msg);
                        file.previewElement.remove();
                    }
                    submitButton.disabled = false;
                },
                sending: () => {
                    submitButton.disabled = true;
                }
            });
        });

        $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function () {
            $("#rr-success-alert").slideUp(500);
        });
        setTimeout(function () {
            $("#rr-success-alert").remove();
        }, 6000);
    },

    handleRemoveAttachment(e) {
        const attachmentId = e.target.dataset.attachmentId
        $(`#deal-sample-request-attachments :input[value="${attachmentId}"]`).remove()
        $(e.target).parents('.dz-preview').remove()
    },

    handleOnClickRemoveAttachment() {
        $(".remove-attachment").on('click', this.handleRemoveAttachment.bind(this))
    },
};
