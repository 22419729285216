window.GoBack = {
    init() {
        this.handleClick();
        this.handleAnalytics();
    },

    handleClick() {
        $(document).ready(function () {
            const goBackButton = document.getElementById("link-back");
            goBackButton.addEventListener("click", () => {
                window.history.back();
            });
        });
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            const goBackButton = document.getElementById("link-back");
            analytics.trackLink(goBackButton, ANALYTICS_EVENTS.BACK_NAVIGATION_SELECTED, {
                page_url: window.location.href,
            })
        });
    }
};
