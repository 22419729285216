window.ResendEmailConfirmation = {
    init() {
        this.handleAnalytics();
        const self = this;
        $("#resend-verify").click(function () {
            self.validateForm()
        });
    },

    emailProviderMsg() {
        return `We do not allow email addresses from ${domain}. Please use your company email to sign up.`
    },

    validateForm() {
        $("#verify-email").validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "user[email]": {
                    required: true,
                    email: true,
                    checkEmailDomain: true
                }
            }
        });

        $.validator.addMethod("checkEmailDomain", function (value) {
            let emailProvider = false
            domain = value.split("@")[1]
            $.ajax({
                url: `users/registration/check_email_domain`,
                data: {'user[email]': value},
                dataType: 'json',
                async: false,
                success: function (data) {
                    emailProvider = data;
                }
            });
            return emailProvider;
        }, this.emailProviderMsg);
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.VERIFY_EMAIL_PAGE_VIEWED)

            const resendVerifyButton = document.getElementById("resend-verify");
            if (resendVerifyButton) {
                resendVerifyButton.addEventListener("click", () => {
                    analytics.track(ANALYTICS_EVENTS.RESEND_EMAIL_SELECTED, {})
                });
            }

            const closeVerifyButton = document.getElementById("close-verify-email-btn");
            analytics.trackLink(closeVerifyButton, ANALYTICS_EVENTS.CLOSE_EMAIL_VERIFICATION, {})
        });
    }
}
