window.ConnectSectors = {
    init() {
        this.handleAnalytics();
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.DESIRED_CATEGORIES_PAGE_VIEWED);

            const connectSectorsSubmitButton = document.getElementById("submit-connect-sectors");
            if (connectSectorsSubmitButton) {
                connectSectorsSubmitButton.addEventListener("click", () => {
                    var categoryIds = document.getElementById('category_ids');
                    var selectedCategories = [];
                    for (var i = 0; i < categoryIds.children.length; i++) {
                        if (categoryIds.children[i].selected) {
                            selectedCategories.push(categoryIds.children[i].textContent);
                        }
                    }

                    analytics.track(ANALYTICS_EVENTS.DESIRED_CATEGORIES_SUBMITTED, {
                        categories: selectedCategories.join(", ")
                    })
                });
            }
        });
    }
};
