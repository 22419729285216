import {AnalyticsBrowser} from "@segment/analytics-next";

const CONFIGS_ENDPOINT = '/configs/fetch_env_vars';
const SEGMENT_KEY_PARAM = 'SEGMENT_KEY';

window.ANALYTICS_EVENTS = Object.freeze({
    BACK_NAVIGATION_SELECTED: "back_navigation_selected",
    ERROR_BANNER_DISPLAYED: "error_banner_displayed",
    USER_IMPERSONATED: "impersonate_user_selected",
    TEAM_VIEW_UPDATED: "team_view_updated",
    SIGN_IN_PAGE_VIEWED: "sign_in_page_viewed",
    LOG_IN_SELECTED: "log_in_selected",
    SIGN_UP_SELECTED: "sign_up_selected",
    USER_TYPE_PAGE_VIEWED: "user_type_page_viewed",
    USER_TYPE_SUBMITTED: "user_type_submitted",
    SUPPLIER_EMAIL_PAGE_VIEWED: "supplier_email_page_viewed",
    SUPPLIER_EMAIL_SUBMITTED: "supplier_email_submitted",
    SIGN_IN_SELECTED: "sign_in_selected",
    USER_DETAILS_PAGE_VIEWED: "user_details_page_viewed",
    USER_DETAILS_SUBMITTED: "user_details_submitted",
    REQUEST_ACCESS_SELECTED: "request_access_selected",
    ADD_NEW_COMPANY_SELECTED: "add_new_company_selected",
    CREATE_COMPANY_PAGE_VIEWED: "create_company_page_viewed",
    COMPANY_INFO_SUBMITTED: "company_info_submitted",
    COMPANY_CATEGORIES_PAGE_VIEWED: "company_categories_page_viewed",
    COMPANY_CATEGORIES_SUBMITTED: "company_categories_submitted",
    DESIRED_CATEGORIES_PAGE_VIEWED: "desired_categories_page_viewed",
    DESIRED_CATEGORIES_SUBMITTED: "desired_categories_submitted",
    COMPANY_TAGS_PAGE_VIEWED: "company_tags_page_viewed",
    COMPANY_TAGS_SUBMITTED: "company_tags_submitted",
    FINAL_DETAILS_PAGE_VIEWED: "final_details_page_viewed",
    FINAL_DETAILS_SUBMITTED: "final_details_submitted",
    VERIFY_EMAIL_PAGE_VIEWED: "verify_email_page_viewed",
    RESEND_EMAIL_SELECTED: "resend_email_selected",
    CLOSE_EMAIL_VERIFICATION: "close_email_verification"
});

class DummySegment {
    identify(...args) {
        console.log("Identify: ", ...args);
    }

    track(...args) {
        console.log("Track: ", ...args);
    }

    trackLink(...args) {
        console.log("Track Link: ", ...args);
    }

    trackSubmit(...args) {
        console.log("Track Submit: ", ...args);
    }

    page(...args) {
        console.log("Page: ", ...args);
    }

    reset() {
        console.log("Reset");
    }
}

(async function initializeAnalytics() {
    try {
        const analyticsInstance = await initializeAnalyticsInstance();
        window.analytics = analyticsInstance[0];
        document.dispatchEvent(new Event('analyticsReady'));
    } catch (error) {
        console.error("Error initializing analytics:", error);
    }
})();

async function initializeAnalyticsInstance() {
    try {
        const writeKey = await fetchSegmentKey();
        if (writeKey) {
            return initializeSegmentAnalytics(writeKey);
        }
        console.error("SEGMENT_KEY is not set. Falling back to DummySegment.");
    } catch (error) {
        console.error("Error fetching Segment key:", error);
    }
    return [new DummySegment()];
}

async function fetchSegmentKey() {
    try {
        const response = await $.ajax({
            url: CONFIGS_ENDPOINT,
            method: 'GET',
            data: {key: SEGMENT_KEY_PARAM},
            dataType: 'json'
        });
        return response[SEGMENT_KEY_PARAM] || "";
    } catch (error) {
        console.error("Failed to fetch SEGMENT_KEY:", error);
        throw new Error("Failed to fetch SEGMENT_KEY");
    }
}

function initializeSegmentAnalytics(writeKey) {
    const analyticsInstance = new AnalyticsBrowser();
    analyticsInstance.load({writeKey});
    return analyticsInstance;
}
