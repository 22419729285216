window.Flash = {
    init() {
        this.handleAnalytics();
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            const flashWrapper = document.getElementById('flash-wrapper');
            if (flashWrapper) {
                const alerts = flashWrapper.querySelectorAll('.error-color');
                alerts.forEach((alert) => {
                    const messageText = alert.innerText.trim();
                    analytics.track(ANALYTICS_EVENTS.ERROR_BANNER_DISPLAYED, {
                        error: messageText,
                    })
                });
            }
        });
    }
};