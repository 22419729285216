window.SignIn = {
    init() {
        this.validateForm();
        this.handleAnalytics();
    },

    validateForm() {
        $("#new_user").validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "user[email]": {
                    required: true,
                    email: true
                },
                "user[password]": {
                    required: true
                }
            },
            errorPlacement: (error, element) => {
                if (element.attr('name') === 'user[password]')
                    error.insertAfter(element.next());
                else
                    error.insertAfter(element);
            }
        });
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.SIGN_IN_PAGE_VIEWED)

            const loginButton = document.getElementById("log-in-btn");
            loginButton.addEventListener("click", () => {
                const loginForm = document.getElementById("new_user");
                analytics.trackSubmit(loginForm, ANALYTICS_EVENTS.LOG_IN_SELECTED, {
                    email: loginForm.elements["user[email]"].value
                })
            });

            const signupLink = document.getElementById("sign-up-btn");
            analytics.trackLink(signupLink, ANALYTICS_EVENTS.SIGN_UP_SELECTED);
        });
    },
};
