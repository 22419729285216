window.ChooseTags = {
    init() {
        this.handleAnalytics();
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.COMPANY_TAGS_PAGE_VIEWED);

            const chooseTagsSubmitButton = document.getElementById("submit");
            if (chooseTagsSubmitButton) {
                chooseTagsSubmitButton.addEventListener("click", () => {
                    let tags = []
                    if ($('#tags').val().length > 0) {
                        tags = JSON.parse($('#tags').val()).map(x => x.value)
                    }
                    analytics.track(ANALYTICS_EVENTS.COMPANY_TAGS_SUBMITTED, {
                        categories: tags.join(", ")
                    })
                });
            }
        });
    }
};
