window.ChooseWorkspace = {
    init() {
        this.handleAnalytics();
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            const requestAccessButtons = document.querySelectorAll("#request-access-btn");
            requestAccessButtons.forEach(button => {
                button.addEventListener("click", () => {
                    analytics.track(ANALYTICS_EVENTS.REQUEST_ACCESS_SELECTED, {
                        company_name: button.dataset.companyName
                    })
                })
            })

            const addNewCompanyButton = document.getElementById("add-new-company-btn");
            analytics.trackLink(addNewCompanyButton, ANALYTICS_EVENTS.ADD_NEW_COMPANY_SELECTED);
        });
    }
};
