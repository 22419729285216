window.ChooseSectors = {
    init() {
        this.handleAnalytics();
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.COMPANY_CATEGORIES_PAGE_VIEWED);

            const chooseSectorsSubmitButton = document.getElementById("submit-choose-sectors");
            if (chooseSectorsSubmitButton) {
                chooseSectorsSubmitButton.addEventListener("click", () => {
                    var categoryIds = document.getElementById('category_ids');
                    var selectedCategories = [];
                    for (var i = 0; i < categoryIds.children.length; i++) {
                        if (categoryIds.children[i].selected) {
                            selectedCategories.push(categoryIds.children[i].textContent);
                        }
                    }

                    analytics.track(ANALYTICS_EVENTS.COMPANY_CATEGORIES_SUBMITTED, {
                        categories: selectedCategories.join(", ")
                    })
                });
            }
        });
    }
};
