window.TeamSelect = {
    init() {
        this.handleChange();
        this.handleAnalytics();
    },

    handleChange() {
        document.addEventListener('DOMContentLoaded', () => {
            const teamSelect = document.getElementById('team_id');
            if (teamSelect) {
                teamSelect.addEventListener('change', (event) => {
                    const selectedTeamId = event.target.value;
                    $.ajax({
                        url: '/select_team/',
                        method: 'post',
                        dataType: 'json',
                        data: {team_id: parseInt(selectedTeamId)},
                        success: function () {
                            if (window.location.href.indexOf('conversations') !== -1) {
                                window.location.href = '/conversations'
                            } else {
                                window.location.reload();
                            }
                        }
                    })
                });
            }
        });
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            const teamSelect = document.getElementById('team_id');
            if (teamSelect) {
                teamSelect.addEventListener('change', (event) => {
                    const selectedTeam = event.target.selectedOptions[0].textContent;
                    analytics.track(ANALYTICS_EVENTS.TEAM_VIEW_UPDATED, {
                        new_team_view: selectedTeam
                    })
                });
            }
        });
    }
};