import {PHONE_EMAIL, WEBSITE_URL_REGEX} from './constants'
import {manualFileUploaded} from './preview_uploaded_file'

window.CompanyDetail = {
    init() {
        this.validateForm();
        this.hanldeManualLogoOnChange();
        this.submitForm();
        this.handleAnalytics();
    },

    hanldeManualLogoOnChange() {
        $('#logo-uploader').change((e) => manualFileUploaded(e, '#logo-reviewer'));
    },
    hanldeTestingCompany(email) {
        let domain = (email.split("@")[1]).split('.')[0]
        if (domain.toLowerCase() == 'kwayga') {
            $('#testing-company').removeClass("hidden")
        }
    },

    validateForm() {
        $("#company_webiste_url").validate({
            onsubmit: true,
            onkeyup: false,
            onclick: true,
            rules: {
                "company[website_url]": {
                    required: true
                },
                "company[name]": {
                    required: true
                },
                "hidden-field-name": {
                    required: true
                }
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                $(element).val($.trim($(element).val()));
                return $(element).valid();
            }
        });

        $.validator.addClassRules("company-website-url", {websiteUrl: true});
        $.validator.addClassRules("hidden-field-c", {phoneUrl: true});

        $.validator.addMethod('websiteUrl', function (value) {
            return WEBSITE_URL_REGEX.test(value);
        }, 'Please enter a valid website');

        $.validator.addMethod('phoneUrl', function (value) {
            return !PHONE_EMAIL.test(value);
        }, 'Sorry, our system has identified contact details in your description, which are not allowed here, please edit your description to proceed - thank you');
    },

    submitForm() {
        $("#company_webiste_url").submit(function () {
            if ($("#company_webiste_url").valid()) {
                $(this).find(":submit").attr('disabled', 'disabled');
            }
        });
    },

    handleAnalytics() {
        document.addEventListener('analyticsReady', () => {
            analytics.page("", ANALYTICS_EVENTS.CREATE_COMPANY_PAGE_VIEWED);

            const companyDetailsSubmitButton = document.getElementById("submit-company-details");
            companyDetailsSubmitButton.addEventListener("click", () => {
                const companyDetailsForm = document.getElementById("company_webiste_url");
                analytics.track(ANALYTICS_EVENTS.COMPANY_INFO_SUBMITTED, {
                    logo_uploaded: !!companyDetailsForm.elements["company[company_detail_attributes][logo]"].value,
                    company_name: companyDetailsForm.elements["company[name]"].value,
                    company_website: companyDetailsForm.elements["company[company_detail_attributes][website_url]"].value,
                    supply_to_large_buyers: companyDetailsForm.elements["company[company_detail_attributes][supply_to_large_buyers]"].value,
                    num_buyers_supplied: companyDetailsForm.elements["company[company_detail_attributes][supply_count]"].value,
                    is_company_testing: companyDetailsForm.elements["company[is_testing]"].value,
                })
            });
        });
    },
};

window.CompanyCategory = {
    init() {
        this.HandleCompanyCategory();
    },

    HandleCompanyCategory() {
        $('.buyer').click(function () {
            $('#supplier').prop('checked', false)
            $('#buyer').prop('checked', true)
            $(this).addClass('form-check-modern-selected')
            $('.supplier').removeClass('form-check-modern-selected')
            $("#company_category_btn").prop("disabled", false);
            $("#company_category_btn").removeClass("disabled");
        });

        $('.supplier').click(function () {
            $('#supplier').prop('checked', true)
            $('#buyer').prop('checked', false)
            $(this).addClass('form-check-modern-selected')
            $('.buyer').removeClass('form-check-modern-selected')
            $("#company_category_btn").prop("disabled", false);
            $("#company_category_btn").removeClass("disabled");
        });
    },
}
